
import GwError from '~/patterns/molecules/error';
import GwEventTemplate from '~/patterns/templates/event';
import FlexibleSection from '~/patterns/organisms/_flexible-section/flexible-section';

import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';
import GwPageTitle from '~/patterns/molecules/page-title/page-title.vue';
import GwPageIntro from '~/patterns/molecules/page-intro/page-intro.vue';
import GwPageMeta from '~/patterns/molecules/page-meta/page-meta.vue';
import GwButton from '~/patterns/atoms/button/button.vue';

import getEventPage from '~/graphql/queries/getEventPage.graphql';
import seo from '~/mixins/seo';

export default {
    components: {
        GwPageContainer,
        GwPageTitle,
        GwPageIntro,
        GwPageMeta,
        GwButton,
        GwError,
        GwEventTemplate,
        FlexibleSection,
    },
    mixins: [seo],

    async asyncData(context) { // nuxt2 workaround for SSR layout reactivity with store
        const site = context.$config.appMultisiteHandle,
            uri = context.route.path.replace(/^\/|\/$/, ''),
            { data } = await context.$gql.executeQuery(
                getEventPage,
                { site, uri }
            );

        context.store.commit('ui/setNavigationCTA', data?.entry?.linkField);

        if (data && data.entry) {
            return {
                entry: data.entry,
                notFound: false
            };
        } else {
            context.error({ statusCode: 404, message: 'Page not found' });
            return {
                entry: null,
                notFound: true
            };
        }
    },

    computed: {
        localizedEventDays() {
            const days = this.entry.eventDays.map((day) => {
                    const formattedDay = this.$d(new Date(day.start), 'dateFull');
                    return formattedDay;
                }),
                locale = this.$config.site.locale,
                formatter = new Intl.ListFormat(locale, { style: 'long', type: 'conjunction' });

            return formatter.format(days);
        }
    },

    nuxtI18n: {
        paths: {
            en: '/events-and-webinars/:slug',
            in: '/events-and-webinars/:slug',
            nl: '/events-and-webinars/:slug',
            fr: '/evenements-et-webinars/:slug',
            de: '/events-und-webinars/:slug',
            'en-Highberg': '/events-and-webinars/:slug',
            'nl-Highberg': '/events-and-webinars/:slug',
            'de-Highberg': '/events-und-webinars/:slug',
        }
    }
};
